import React from "react"
import Layout from "../components/layout.js"

export default () => (
  <div>
    <Layout>
      <h1>Tom Runyon</h1>
      <p>
        Tom specializes in adopting declarative patterns, where operation teams
        can declare their environment declaratively using tools like Terraform,
        Kubernetes
      </p>
    </Layout>
  </div>
)
